import {API} from 'aws-amplify';
import axios from "axios";
export const getUsersApi = (pageNo, pageSize) =>
  API.get('Backend', "/user/", {
    params: { pageNo, pageSize }
  });
  export const removeUserApi = async(deleteUserEmail,loggedInUserEmail) => {

    try{
      const res= await API.post('Backend', '/user/deleteUser', {
        body: {
          deleteEmail: deleteUserEmail,
          loggedInUserEmail
        },
      });
      return res.data; 
    }catch(err){
     
      
      throw err.response.data?.error;
    }
 
  };

  export const checkAdmin = async ({email}) => {
    try {
      console.log("Calling checkAdmin with email:", email);
      const response = await axios.post(`${process.env.REACT_APP_APIURL}/user/checkAdmin`, {
        email,
      });
      console.log("Response from checkAdmin:", response.data);
      return response.data; 
    } catch (error) {
      console.error("Error in checkAdmin:", error);
      throw error;
    }
  };
  export const updateUserRoleApi = async(payload) => {
   
    try{
      const res= await API.post('Backend', '/user/updateRole', {
        body: payload
      });
      return res; 
    }catch(err){
      
      throw err.response.data?.error;
    }
 
  };
  