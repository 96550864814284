import React, { useState, useEffect} from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Pagination } from "@material-ui/lab";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";
import { loadUserAction } from "../../Redux/Actions/Users";
import { userRemoveAction } from "../../Redux/Actions/Users";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import DeleteIcon from "@material-ui/icons/Delete";
import { MenuItem, Select } from "@material-ui/core";
import { UserRole } from "enums/userRole.enum";
import { select } from "redux-saga/effects";
import { getUser } from "Redux/Selectors";
import './userList.style.css'
import { Auth } from "aws-amplify";
import { updateUserRoleApi } from "Redux/sagas/users/Api";


const tableHead = ["Sr #","First Name", "Last Name", "Email", "User Name", "Avatar Url",'Role',"Actions"];
const UsersList = (props) =>  {
  const [xPages, setPages] = useState(1)
  const [users, setUsers] = useState(props.Users.users)
  const [currentUserRole,setCurrentUserRole]=useState(null)
  const [currentUser,setCurrentUser]=useState(null)

  const classes = styles;
  const user =  useSelector(getUser);
  
  useEffect(() => {
     
    props.getUsers(1, 10);
  }, []) 
  useEffect(() => {
    
    if(users&&users?.length!==0 && user)
    {
      const currentUser = users?.filter((item)=>item?.email==user?.attributes?.email)
   
      setCurrentUserRole(currentUser[0]?.role)
    }
    setCurrentUser(user?.attributes?.email)
  },[user,users])
  
  const updatePages = () => {
    if(users !== props.Users.users) {
      setUsers(props.Users.users)
      let pages = props.Users.pageCount / 10;
      if(pages % 1 !== 0){
        pages = parseInt(pages) + 1;
      }
      setPages(pages);
    }
  }
  const handleRoleChange=async(id,newRole)=>{
 await updateUserRoleApi({id,newRole});

  
  const temp=[...users]
  
  const index = temp?.findIndex(user => user._id === id);

    // If the user is found in the array, update their role
    if (index !== -1) {
      temp[index].role = newRole;  // Update the role of the user at the found index
    } else {
      console.log('User not found in temp array');
    }
    setUsers(temp);

  }
  const mapUsers = () =>
    users?.map((user, index) => [
      index,
      user.firstName,
      user.lastName,
      user.email,
      user.userName,
      user.url,
      <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={user?.role}
      disabled={(currentUserRole=='superAdmin')?(user?.role!=='superAdmin'?false:true):(currentUserRole=='admin'?((user?.role!=='superAdmin'&&user?.role!=='admin')?false:true):true)}
      label=""
      onChange={(e)=>handleRoleChange(user?._id,e?.target?.value)}
    >
      <MenuItem value={UserRole.CLIENT}>Client</MenuItem>
      <MenuItem value={UserRole.SUPER_ADMIN}>Admin</MenuItem>
      <MenuItem value={UserRole.ADMIN}>Secondary Admin</MenuItem>
    </Select>
      ,
      <button className={`squareBtn red ${(currentUser===user?.email||((currentUserRole=='superAdmin')?(user?.role!=='superAdmin'?false:true):(currentUserRole=='admin'?((user?.role!=='superAdmin'&&user?.role!=='admin')?false:true):true)))?"not-allowed":""}`}
      disabled={Boolean(currentUser===user?.email||((currentUserRole=='superAdmin')?(user?.role!=='superAdmin'?false:true):(currentUserRole=='admin'?((user?.role!=='superAdmin'&&user?.role!=='admin')?false:true):true)))}
      onClick={()=>{
        console.log("The user is",user);
        
        props.userRemove(user.email)
      }}><DeleteIcon fontSize="small" htmlColor="#fff" /></button>,
    ]);
    
  const onPageChange = (...params) => {
    props.getUsers(params[1], 10)
  };



  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes?.cardTitleWhite}>Users</h4>
          </CardHeader>
          <CardBody>
           { currentUserRole&&<Table
              tableHeaderColor="primary"
              tableHead={tableHead}
              tableData={props.Users.users&&currentUserRole ? mapUsers() : []}
            />}
          </CardBody>
          <GridItem xs={12} sm={12} md={12}>
            <div style={classes?.paginationContainer}>
              <ThemeProvider theme={theme}>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                  defaultPage={updatePages() && 1}
                  onChange={onPageChange}
                  count={
                    xPages
                  }
                />
              </ThemeProvider>
            </div>
          </GridItem>
        </Card>
      </GridItem>
    </GridContainer>
  )
}



const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor[0]
    }
  }
});

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "10px"
  }
};

const mapStoreToProps = store => ({ 
  Users: store.Users 
});

const mapDispatchToProps = dispatch => {
  return {
    getUsers: (pageNumber, pageSize) => dispatch(loadUserAction(pageNumber, pageSize)),
    userRemove: (email) => dispatch(userRemoveAction(email))
  }
}
export default connect(mapStoreToProps, mapDispatchToProps)(UsersList);
